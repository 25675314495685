// @ts-nocheck
import React from 'react';
import {
  isMultipleChoiceQuestion,
  isNumericInputQuestion,
  MultipleChoiceQuestion,
  NumericInputQuestion,
  SurveyAnswer,
  SurveyQuestion,
  SurveyQuestionType,
} from '../../types';
import { LongAnswer } from './LongAnswer/LongAnswer';
import { MultipleChoiceQuestionComponent } from './OptionAnswer/MultipleChoiceAnswer';
import { QuestionContainer, QuestionTitle, DoneButton } from './styles';
import { NumericInputQuestionComponent } from './NumericInput';
import { isArray } from 'lodash';

interface Props {
  question: SurveyQuestion;
  isLastQuestion: boolean;
  onAnswer?: (newValue: string | string[] | number) => any;
}

const AnswerComponent: React.FC<{
  onAnswer: (answer: string | string[] | number) => any;
  question: SurveyQuestion;
}> = ({ onAnswer, question }) => {
  // Would like to use a switch case here but ts complains
  // and readability is better than the 0.01 ns that we will gain
  if (isMultipleChoiceQuestion(question)) {
    return (
      <MultipleChoiceQuestionComponent
        question={question}
        key={question.id}
        options={question.options}
        onAnswer={onAnswer}
        id={question.id}
      />
    );
  }
  if (isNumericInputQuestion(question)) {
    return (
      <NumericInputQuestionComponent key={question.id} onAnswer={onAnswer} />
    );
  }

  return <LongAnswer key={question.id} onAnswer={onAnswer} />;
};

// TODO: Add some proper f***ing validation
const isValidAnswer = (
  question: SurveyQuestion | MultipleChoiceQuestion | NumericInputQuestion,
  answer: SurveyAnswer | null
) => {
  if (isNumericInputQuestion(question)) {
    return answer !== null && !isNaN(parseFloat(answer.toString()));
  }
  if (isMultipleChoiceQuestion(question)) {
    if (!isArray(answer)) {
      return false;
    }

    if (
      question.minAnswersSelected &&
      answer.length < question.minAnswersSelected
    ) {
      return false;
    }
    if (
      question.maxAnswersSelected &&
      answer.length > question.maxAnswersSelected
    ) {
      return false;
    }
  }
  return true;
};

export const SurveyQuestionComponent: React.FC<Props> = ({
  question,
  onAnswer = () => {},
  isLastQuestion = false,
}) => {
  const [currentAnswer, setAnswer] = React.useState<SurveyAnswer>(
    question.type === SurveyQuestionType.NumericInput ? 0 : []
  );
  const onDoneClick = () => {
    onAnswer(currentAnswer);
  };
  return (
    <QuestionContainer>
      <QuestionTitle>{question.message}</QuestionTitle>
      <AnswerComponent question={question} onAnswer={setAnswer} />
      <DoneButton
        role="next-question"
        onClick={onDoneClick}
        variant="secondary"
        disabled={!isValidAnswer(question, currentAnswer)}
      >
        {isLastQuestion ? 'Submit' : 'Next'}
      </DoneButton>
    </QuestionContainer>
  );
};
