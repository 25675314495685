// @ts-nocheck
import React from 'react';
import { Form } from 'react-bootstrap';
import styled from '@emotion/styled';

const LongAnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const LongAnswer: React.FC<{ onAnswer: (newValue: string) => any }> = ({
  onAnswer,
}) => {
  const onChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) =>
    onAnswer(evt.target.value);
  return (
    <LongAnswerContainer>
      <Form.Control as="textarea" onChange={onChange}></Form.Control>
    </LongAnswerContainer>
  );
};
