import React from 'react';
import createBrowserHistory from 'history/lib/createBrowserHistory';
import { Router, useRouterHistory } from 'react-router';
import createStore from './store/createStore';
import { Provider } from 'react-redux';
// import ReactGA from 'react-ga';
import RoutesGenerator from './routes';

// ReactGA.initialize('UA-96034248-1');
// ========================================================
// Store and History Instantiation
// ========================================================
const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState);

const browserHistory = useRouterHistory(createBrowserHistory)({
  basename: window.__BASENAME__,
});

const onUpdate = () => {
  var contentDiv = document.getElementsByClassName('wrapper')[0];
  if (contentDiv) {
    contentDiv.scrollTop = 0;
  }
  //ReactGA.set({ page: window.location.pathname });
  //ReactGA.pageview(window.location.pathname);
};

const App = ({ key = null }) => {
  const routes = RoutesGenerator(store);
  return (
    <Provider store={store}>
      <div style={{ height: '100%' }}>
        <Router
          history={browserHistory}
          children={routes}
          onUpdate={onUpdate}
        />
      </div>
    </Provider>
  );
};

export default App;
